import {
  Card,
  CardContent,
  CircularProgress,
  Skeleton,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import { getformatDateFromISOFormat } from "../../components/Utils/utils";
import TicketDetails from "../../interfaces/ticket";
import axiosConfig from "../../axiosConfig";

const OrderDetails: React.FC<{
  orderTicketId?: string;
}> = ({ orderTicketId }) => {

  const [orderDetails, setOrderDetails] = React.useState<TicketDetails>();

  const fetchOrderDetails = async () => {
    const response = await axiosConfig.get(
      `/development/api/dashboard/tickets/${orderTicketId}`
    );
    setOrderDetails(response.data.body);
  }

  useEffect(() => {
    fetchOrderDetails();
  }, []);

  if (!orderDetails) {
    return (
      <div className="flex flex-col" style={{ marginBottom: "200px" }}>
        <div
          style={{
            padding: "20px",
            width: "60vw",
            margin: "auto",
          }}
        >
          <Skeleton
            variant="text"
            width="60%"
            height={50}
            style={{ marginTop: "20px" }}
          />
          <Skeleton variant="text" width="60%" height={30} />
          <Skeleton variant="text" width="80%" height={30} />
          <Skeleton variant="text" width="90%" height={30} />
          <Skeleton variant="text" width="50%" height={30} />
          <Skeleton
            variant="text"
            width="60%"
            height={50}
            style={{ marginTop: "20px" }}
          />
          <Skeleton variant="text" width="60%" height={30} />
          <Skeleton variant="text" width="80%" height={30} />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col" style={{ marginBottom: "0px" }}>
      <InfoCard title="注文情報" width={"60vw"}>
        <Typography>
          <strong>発注ID:</strong> {orderDetails?.order_id}
        </Typography>
        <Typography>
          <strong>案件名:</strong> {orderDetails?.project_name}
        </Typography>
        <Typography>
          <strong>見積番号:</strong> {orderDetails?.estimation_number}
        </Typography>
        <Typography>
          <strong>作成日:</strong>{" "}
          {getformatDateFromISOFormat(orderDetails?.created_at ?? "")}
        </Typography>
        <Typography>
          <strong>会社名:</strong> {orderDetails?.company_name}
        </Typography>
        <Typography>
          <strong>住所:</strong> {orderDetails?.address}
        </Typography>
        <Typography>
          <strong>住所2:</strong> {orderDetails?.address2}
        </Typography>
        <Typography>
          <strong>郵便番号:</strong> {orderDetails?.postal_code}
        </Typography>
        <Typography>
          <strong>Email:</strong> {orderDetails?.email}
        </Typography>
        <Typography>
          <strong>電話番号:</strong> {orderDetails?.phone_number}
        </Typography>
        <Typography>
          <strong>担当者のお名前:</strong> {orderDetails?.pic}
        </Typography>
        <Typography>
          <strong>小計:</strong> {orderDetails?.sub_total}
        </Typography>
        <Typography>
          <strong>消費税:</strong> {orderDetails?.consumption_tax}
        </Typography>
        <Typography>
          <strong>合計:</strong> {orderDetails?.total_amount}
        </Typography>
      </InfoCard>

      <InfoCard title="顧客情報" width={"60vw"}>
        <Typography>
          <strong>顧客ID:</strong> {orderDetails?.customer_id}
        </Typography>
        <Typography>
          <strong>顧客名:</strong> {orderDetails?.customer_name}
        </Typography>
      </InfoCard>
    </div>
  );
};

const InfoCard: React.FC<{
  title: string;
  children: React.ReactNode;
  width?: string;
}> = ({ title, children, width }) => {
  return (
    <Card
      className="mx-auto mt-4"
      sx={{
        border: 1,
        borderColor: "grey.300",
        borderRadius: "10px",
        width: width ?? "90vw",
      }}
    >
      <CardContent
        sx={{
          "& > *": {
            marginBottom: 1,
          },
        }}
      >
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: "bold",
            marginBottom: 1,
          }}
        >
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

export default OrderDetails;
