export type Order = {
  id: string;
  estimation_number: string;
  project_name: string;
  customer_name: string;
  pic: string;
  pic_ruby: string;
  phone_number: string;
  created_at: string;
};

export type Ticket = {
  id: string;
  progress: number;
  subcontractor_id: string;
  subcontractor_name: string;
  status: number;
  created_at: string;
  delivery_at: string;
  file_name: string;
};

export type DataOrder = {
  order: Order;
  tickets: Ticket[];
};

export type FormData = {
  username: string;
  email: string;
  password: string;
  fullname: string;
  phone_number: string;
  confirmPassword: string;
  type: string;
  [key: string]: string | "";
};

export type ResDataOrder = {
  message: string;
  body: PaginationResponse<DataOrder>;
};

export const TicketStatus = [
  "CUSTOMER_CREATED",
  "CUSTOMER_ORDERED",
  "COMPANY_ORDER_RECEIVED",
  "COMPANY_ASSIGNED",
  "COMPANY_ORDER_REJECTED",
  "COMPANY_DELIVERY_LATE",
  "SUB_ORDER_ACCEPTED",
  "SUB_INPROGRESS",
  "SUB_DELIVERED",
  "SUB_ORDER_REJECTED",
  "SUB_DELIVERY_LATE",
  "COMPANY_INSPECTION_ONGOING",
  "COMPANY_INSPECTION_COMPLETED",
  "COMPANY_DELIVERED",
  "COMPANY_REDO_REQUESTED",
  "CUSTOMER_INSPECTION_ONGOING",
  "CUSTOMER_INSPECTION_COMPLETED",
  "CUSTOMER_DELIVERY_REJECTED",
];

export const displayStatusCustomer: Partial<Record<string, string>> = {
  CUSTOMER_CREATED: "見積作成済み",
  CUSTOMER_ORDERED: "発注済み受託待ち",
  SUB_INPROGRESS: "作業中",
  CUSTOMER_INSPECTION_ONGOING: "検収してください",
  CUSTOMER_INSPECTION_COMPLETED: "納品完了",
  CUSTOMER_DELIVERY_REJECTED: "検収不合格のため確認中",
  COMPANY_ORDER_RECEIVED: "作業中",
  COMPANY_ASSIGNED: "作業中",
  COMPANY_DELIVERY_LATE: "作業中",
  SUB_ORDER_ACCEPTED: "作業中",
  SUB_DELIVERED: "作業中",
  SUB_ORDER_REJECTED: "作業中",
  SUB_DELIVERY_LATE: "作業中",
  COMPANY_DELIVERED: "顧客納品受入待ち（検収してください）",
  COMPANY_ORDER_REJECTED: "自社受注拒否済み",
  COMPANY_REDO_REQUESTED: "作業中",
  COMPANY_INSPECTION_ONGOING: "作業中",
  COMPANY_INSPECTION_COMPLETED: "作業中",
};

export const stepperStatusCustomer: Partial<Record<string, string>> = {
  CUSTOMER_CREATED: "見積作成済み",
  CUSTOMER_ORDERED: "発注済み受託待ち",
  ...["SUB_INPROGRESS", "COMPANY_ORDER_RECEIVED", "COMPANY_ASSIGNED", "COMPANY_DELIVERY_LATE", "SUB_ORDER_ACCEPTED", "SUB_DELIVERED", "SUB_ORDER_REJECTED", "SUB_DELIVERY_LATE", "COMPANY_INSPECTION_ONGOING", "COMPANY_INSPECTION_COMPLETED", "COMPANY_REDO_REQUESTED"].reduce((acc, key) => ({ ...acc, [key]: "作業中" }), {}),
  COMPANY_DELIVERED: "自社納品済み",
  CUSTOMER_INSPECTION_ONGOING: "検収してください",
  CUSTOMER_INSPECTION_COMPLETED: "納品完了",
};

export const displayStatusCompany: Partial<Record<string, string>> = {
  CUSTOMER_CREATED: "顧客見積作成済み",
  CUSTOMER_ORDERED: "顧客発注済み受諾待ち",
  COMPANY_ORDER_RECEIVED: "自社受注済み",
  COMPANY_ASSIGNED: "自社発注済み受諾待ち",
  COMPANY_ORDER_REJECTED: "自社受注拒否済み",
  COMPANY_DELIVERY_LATE: "自社作業超過",
  SUB_ORDER_ACCEPTED: "外注先受注済み",
  SUB_INPROGRESS: "外注先作業中",
  SUB_DELIVERED: "外注先納品済み",
  SUB_ORDER_REJECTED: "外注先受注拒否済み",
  SUB_DELIVERY_LATE: "外注先作業超過",
  COMPANY_INSPECTION_ONGOING: "自社検品中",
  COMPANY_INSPECTION_COMPLETED: "自社検品済み",
  COMPANY_DELIVERED: "自社納品済み",
  COMPANY_REDO_REQUESTED: "自社やり直し依頼済み",
  CUSTOMER_INSPECTION_ONGOING: "顧客納品受入待ち",
  CUSTOMER_INSPECTION_COMPLETED: "顧客納品受入済み",
  CUSTOMER_DELIVERY_REJECTED: "顧客納品拒否済み",
};

export const stepperStatusCompany: Partial<Record<string, string>> = {
  CUSTOMER_CREATED: "顧客見積作成済み",
  CUSTOMER_ORDERED: "顧客発注済み受諾待ち",
  COMPANY_ORDER_RECEIVED: "自社受注済み",
  COMPANY_ASSIGNED: "自社発注済み受諾待ち",
  SUB_ORDER_ACCEPTED: "外注先受注済み",
  SUB_INPROGRESS: "外注先作業中",
  SUB_DELIVERED: "外注先納品済み",
  COMPANY_INSPECTION_ONGOING: "自社検品中",
  COMPANY_INSPECTION_COMPLETED: "自社検品済み",
  COMPANY_DELIVERED: "自社納品済み",
  CUSTOMER_INSPECTION_ONGOING: "顧客納品受入待ち",
  CUSTOMER_INSPECTION_COMPLETED: "顧客納品受入済み",
};

export const displayStatusSubcontractor: Partial<Record<string, string>> = {
  COMPANY_ASSIGNED: "受諾待ち",
  SUB_ORDER_ACCEPTED: "受注済み",
  SUB_INPROGRESS: "作業中",
  SUB_ORDER_REJECTED: "受注拒否済み",
  SUB_DELIVERED: "納品済み",
  COMPANY_REDO_REQUESTED: "やり直し依頼",
};

export const stepperStatusSubcontractor: Partial<Record<string, string>> = {
  COMPANY_ASSIGNED: "受諾待ち",
  SUB_ORDER_ACCEPTED: "受注済み",
  SUB_INPROGRESS: "作業中",
  SUB_DELIVERED: "納品済み",
};

export const displayStatusGanttCompany: any = {
  COMPANY_ORDER_RECEIVED: "受注済み",
  COMPANY_ASSIGNED: "外注先受諾待ち",
  SUB_ORDER_ACCEPTED: "外注先受注",
  SUB_INPROGRESS: "外注先作業中",
  SUB_ORDER_REJECTED: "	外注先受注拒否",
  SUB_DELIVERED: "外注先納品",
  COMPANY_INSPECTION_ONGOING: "自社検品中",
  COMPANY_INSPECTION_COMPLETED: "自社検品済み",
  COMPANY_REDO_REQUESTED: "やり直し依頼済み",
  CUSTOMER_INSPECTION_ONGOING: "顧客納品受入待ち",
  CUSTOMER_INSPECTION_COMPLETED: "納品完了",
  CUSTOMER_DELIVERY_REJECTED: "納品拒否",
};

export const displayStatusGanttSubcontractor: any = {
  SUB_ORDER_ACCEPTED: "受注",
  SUB_INPROGRESS: "作業中",
  SUB_ORDER_REJECTED: "受注拒否",
  SUB_DELIVERED: "納品",
  COMPANY_REDO_REQUESTED: "やり直し依頼",
};

export type PaginationData = {
  page: number;
  per_page: number;
  page_count: number;
  total_count: number;
};

export type PaginationResponse<T> = {
  data: T[];
  metadata: PaginationData;
};
