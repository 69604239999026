import {
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Card,
  CardHeader,
  Button,
  CardContent,
  Autocomplete,
  TextField,
  Stack,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Box,
  CircularProgress,
  Menu,
  Fade,
  ListItemIcon,
  MenuItem,
} from "@mui/material";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import GanttChart from "../../components/Dashboard/GanttChart";
import {
  Ticket,
  DataOrder,
  ResDataOrder,
  TicketStatus,
  displayStatusCompany,
} from "../../common/constants";
import axiosConfig from "../../axiosConfig";
import { useSearchParams } from "react-router-dom";
import TicketDetail from "../TicketDetail";
import { Close, InfoOutlined, MoreVert, SaveAlt } from "@mui/icons-material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { jaJP } from "@mui/x-data-grid/locales";
import LoadingDialog from "../../components/LoadingDialog/LoadingDialog";
import { getformatDateFromISOFormat } from "../../components/Utils/utils";
import ListAltIcon from "@mui/icons-material/ListAlt";
import ConfirmDialog, { ConfirmDialogType } from "./ConfirmDialog";
import DownloadImageDialog from "./DownloadImageDialog";
import OrderDetails from "./OrderDetails";

function Row(props: {
  row: DataOrder;
  setTicketId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setTicketIdSubDialog: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  fetchData: () => void;
  setOrderTicketId: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const { row, setTicketId, setTicketIdSubDialog } = props;
  const [open, setOpen] = React.useState(false);
  const [dialogType, setDialogType] = useState<ConfirmDialogType | null>(null);
  const [openAcceptOrRejectDialog, setOpenAcceptOrRejectDialog] =
    useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [openDownloadDialog, setOpenDownloadDialog] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [imageData, setImageData] = React.useState<string | null>(null);
  const [imageName, setImageName] = React.useState<string | null>(null);
  const [selectedTicket, setSelectedTicket] = React.useState<Ticket>();
  const [isRefresh, setIsRefresh] = React.useState(false);

  const handleDownloadIntialFileClick = async () => {
    setImageData(null);
    setOpenDownloadDialog(true);
    setLoading(true);

    try {
      const response = await axiosConfig({
        method: "get",
        url: `/development/api/dashboard/download-drawing/${selectedTicket?.id}`,
      });

      // Store image data & filename in state
      setImageData(response.data);
      setImageName(selectedTicket!.file_name);
    } catch (error) {
      console.error("Error downloading file", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadResultFileClick = async () => {
    setImageData(null);
    setOpenDownloadDialog(true);
    setLoading(true);

    try {
      const response = await axiosConfig({
        method: "get",
        url: `/development/api/sub-contractor/image/download/${selectedTicket?.id}`,
      });

      // Store image data & filename in state
      setImageData(response.data.file_content);
      setImageName(response.data.file_name);

      if (
        TicketStatus.indexOf("SUB_DELIVERED") === selectedTicket?.status ||
        TicketStatus.indexOf("SUB_DELIVERY_LATE") === selectedTicket?.status ||
        TicketStatus.indexOf("COMPANY_DELIVERY_LATE") === selectedTicket?.status
      ) {
        await axiosConfig.patch(
          `/development/api/dashboard/tickets/${selectedTicket?.id}`,
          {
            status: TicketStatus.indexOf("COMPANY_INSPECTION_ONGOING"),
          }
        );
        setIsRefresh(true);
      }
    } catch (error) {
      console.error("Error downloading file", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDownloadDialog(false);
  };

  const handleDownloadFromDialog = () => {
    if (imageData) {
      // Decode base64 string
      const byteCharacters = atob(imageData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Create a Blob object with the correct MIME type
      const blob = new Blob([byteArray], {
        type: `image/${imageName?.split(".").pop() || "png"}`,
      });

      // Create a URL for the Blob object
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", imageName || "file");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  const handleConfirmButtonClicked = async () => {
    if (selectedTicket) {
      if (dialogType === ConfirmDialogType.employeeConfirmDelivery) {
        await axiosConfig.post(`/development/api/dashboard/employee-deliver`, {
          ticket_id: selectedTicket.id,
        });

        props.fetchData();

        setOpenAcceptOrRejectDialog(false);

        return;
      }

      let status = 0;
      let progress = null;
      if (dialogType === ConfirmDialogType.employeeAcceptCustomer) {
        status = TicketStatus.indexOf("COMPANY_ORDER_RECEIVED");
      } else if (dialogType === ConfirmDialogType.employeeRejectCustomer) {
        status = TicketStatus.indexOf("COMPANY_ORDER_REJECTED");
      } else if (dialogType === ConfirmDialogType.employeeAcceptSubcontractor) {
        status = TicketStatus.indexOf("COMPANY_INSPECTION_COMPLETED");
      } else if (dialogType === ConfirmDialogType.employeeRejectSubcontractor) {
        status = TicketStatus.indexOf("COMPANY_REDO_REQUESTED");
        progress = 0;
      }
      await axiosConfig.patch(
        `/development/api/dashboard/tickets/${selectedTicket.id}`,
        {
          status: status,
          progress: progress,
        }
      );

      props.fetchData();
    }
    setOpenAcceptOrRejectDialog(false);
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": { borderBottom: "unset" },
        }}
      >
        <TableCell sx={{ width: "30px" }}>
          {row.tickets.length > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell
          sx={{
            width: "10%",
          }}
        >
          {row.order.estimation_number}
        </TableCell>
        <TableCell
          sx={{
            width: "30%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {row.order.project_name}
        </TableCell>
        <TableCell
          sx={{
            width: "15%",
          }}
        >
          {row.order.pic}
        </TableCell>
        <TableCell
          sx={{
            width: "15%",
          }}
        >
          {row.order.pic_ruby}
        </TableCell>
        <TableCell
          sx={{
            width: "10%",
          }}
        >
          {row.order.phone_number}
        </TableCell>
        <TableCell>
          <div style={{ display: "flex" }}>
            <Button
              variant="contained"
              size="small"
              disableRipple
              sx={{ marginLeft: "auto" }}
              onClick={() => {
                props.setOrderTicketId(row.tickets[0].id);
              }}
            >
              詳細
            </Button>
          </div>
        </TableCell>
      </TableRow>
      {row.tickets.length > 0 && (
        <TableRow>
          <TableCell sx={{ padding: 0 }} colSpan={12}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Table
                size="small"
                sx={{
                  width: "100%",
                  tableLayout: "fixed",
                }}
              >
                <TableBody>
                  {row.tickets
                    .filter((ticketRow) => {
                      return ticketRow.id !== null;
                    })
                    .map((ticketRow: Ticket) => {
                      const isLate =
                        TicketStatus[ticketRow.status] ===
                          "COMPANY_DELIVERY_LATE" ||
                        TicketStatus[ticketRow.status] === "SUB_DELIVERY_LATE";
                      return (
                        <React.Fragment key={ticketRow.id}>
                          <TableRow
                            key={ticketRow.id}
                            sx={{
                              backgroundColor: isLate ? "#fae0e4" : "",
                            }}
                          >
                            <TableCell
                              sx={{
                                width: "5%",
                              }}
                            />
                            <TableCell
                              align="left"
                              sx={{
                                width: "30%",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {ticketRow.file_name}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                width: "15%",
                              }}
                            >
                              {getformatDateFromISOFormat(
                                ticketRow.delivery_at
                              )}
                            </TableCell>
                            <TableCell
                              align="left"
                              sx={{
                                width: "20%",
                              }}
                            >
                              {
                                displayStatusCompany[
                                  TicketStatus[ticketRow.status]
                                ]
                              }
                            </TableCell>
                            <TableCell>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "flex-end",
                                }}
                              >
                                {(TicketStatus[ticketRow.status] ===
                                  "COMPANY_ORDER_RECEIVED" ||
                                  TicketStatus[ticketRow.status] ===
                                    "CUSTOMER_DELIVERY_REJECTED" ||
                                  TicketStatus[ticketRow.status] ===
                                    "SUB_ORDER_REJECTED") && (
                                  <Button
                                    variant="contained"
                                    size="small"
                                    disableRipple
                                    onClick={() => {
                                      setTicketIdSubDialog(ticketRow.id);
                                    }}
                                  >
                                    チケット個別アサイン
                                  </Button>
                                )}
                                {TicketStatus[ticketRow.status] ===
                                  "CUSTOMER_ORDERED" && (
                                  <Stack
                                    spacing={2}
                                    direction="row"
                                    sx={{ marginLeft: "auto" }}
                                  >
                                    <Button
                                      variant="contained"
                                      size="small"
                                      disableRipple
                                      style={{
                                        backgroundColor: "#DAE8FC",
                                        color: "#000",
                                      }}
                                      onClick={() => {
                                        setSelectedTicket(ticketRow);
                                        setDialogType(
                                          ConfirmDialogType.employeeAcceptCustomer
                                        );
                                        setOpenAcceptOrRejectDialog(true);
                                      }}
                                    >
                                      受注確定
                                    </Button>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      disableRipple
                                      color="error"
                                      style={{
                                        backgroundColor: "#F8CECC",
                                        color: "#000",
                                      }}
                                      onClick={() => {
                                        setSelectedTicket(ticketRow);
                                        setDialogType(
                                          ConfirmDialogType.employeeRejectCustomer
                                        );
                                        setOpenAcceptOrRejectDialog(true);
                                      }}
                                    >
                                      受注拒否
                                    </Button>
                                  </Stack>
                                )}
                                {TicketStatus[ticketRow.status] ===
                                  "COMPANY_INSPECTION_ONGOING" && (
                                  <Stack
                                    spacing={2}
                                    direction="row"
                                    sx={{ marginLeft: "auto" }}
                                  >
                                    <Button
                                      variant="contained"
                                      size="small"
                                      disableRipple
                                      onClick={() => {
                                        setSelectedTicket(ticketRow);
                                        setDialogType(
                                          ConfirmDialogType.employeeAcceptSubcontractor
                                        );
                                        setOpenAcceptOrRejectDialog(true);
                                      }}
                                    >
                                      受入完了
                                    </Button>
                                    <Button
                                      variant="contained"
                                      size="small"
                                      disableRipple
                                      color="error"
                                      onClick={() => {
                                        setSelectedTicket(ticketRow);
                                        setDialogType(
                                          ConfirmDialogType.employeeRejectSubcontractor
                                        );
                                        setOpenAcceptOrRejectDialog(true);
                                      }}
                                    >
                                      受入拒否
                                    </Button>
                                  </Stack>
                                )}
                                {TicketStatus[ticketRow.status] ===
                                  "COMPANY_INSPECTION_COMPLETED" && (
                                  <Button
                                    variant="contained"
                                    size="small"
                                    disableRipple
                                    style={{
                                      backgroundColor: "#D5E8D4",
                                      color: "#000",
                                    }}
                                    onClick={() => {
                                      setSelectedTicket(ticketRow);
                                      setDialogType(
                                        ConfirmDialogType.employeeConfirmDelivery
                                      );
                                      setOpenAcceptOrRejectDialog(true);
                                    }}
                                  >
                                    納品する
                                  </Button>
                                )}
                                <IconButton
                                  id={ticketRow.id}
                                  aria-controls={
                                    openMenu ? "fade-menu" : undefined
                                  }
                                  aria-haspopup="true"
                                  aria-expanded={openMenu ? "true" : undefined}
                                  onClick={(
                                    event: React.MouseEvent<HTMLElement>
                                  ) => {
                                    setAnchorEl(event.currentTarget);
                                    setSelectedTicket(ticketRow);
                                  }}
                                  sx={{
                                    marginLeft: "20px",
                                  }}
                                >
                                  <MoreVert />
                                </IconButton>
                              </Box>
                            </TableCell>
                          </TableRow>
                        </React.Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
          <Menu
            MenuListProps={{
              "aria-labelledby": "fade-button",
            }}
            anchorEl={anchorEl}
            open={openMenu}
            onClose={() => {
              setAnchorEl(null);
            }}
            TransitionComponent={Fade}
          >
            <MenuItem
              onClick={() => {
                handleDownloadIntialFileClick();
                setImageName(selectedTicket!.file_name);
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <SaveAlt
                  sx={{
                    color: "#4CAF50",
                  }}
                />
              </ListItemIcon>
              <Typography
                variant="inherit"
                sx={{
                  color: "#4CAF50",
                }}
              >
                ファイル
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                handleDownloadResultFileClick();
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <SaveAlt
                  sx={{
                    color: "#4CAF50",
                  }}
                />
              </ListItemIcon>
              <Typography
                variant="inherit"
                sx={{
                  color: "#4CAF50",
                }}
              >
                納品物
              </Typography>
            </MenuItem>
            <MenuItem
              onClick={() => {
                setTicketId(selectedTicket?.id);
                setAnchorEl(null);
              }}
            >
              <ListItemIcon>
                <InfoOutlined
                  sx={{
                    color: "#3964B1",
                  }}
                />
              </ListItemIcon>
              <Typography
                variant="inherit"
                sx={{
                  color: "#3964B1",
                }}
              >
                詳細
              </Typography>
            </MenuItem>
          </Menu>
          <DownloadImageDialog
            open={openDownloadDialog}
            onClose={() => {
              handleCloseDialog();
              if (isRefresh) {
                props.fetchData();
                setIsRefresh(false);
              }
            }}
            imageUrl={imageData || ""}
            imageType={`image/${
              selectedTicket?.file_name.split(".").pop() || "png"
            }`}
            onDownload={handleDownloadFromDialog}
            loading={loading}
          />
          <ConfirmDialog
            dialogType={dialogType!}
            open={openAcceptOrRejectDialog}
            onClose={() => {
              setOpenAcceptOrRejectDialog(false);
            }}
            onConfirm={handleConfirmButtonClicked}
            estimationNumber={row.order.estimation_number}
            fileName={selectedTicket?.file_name || ""}
          />
        </TableRow>
      )}
    </React.Fragment>
  );
}

const EmployeeDashboard: React.FC = () => {
  const [showSearchContent, setShowSearchContent] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [data, setData] = useState<ResDataOrder>({
    message: "",
    body: {
      data: [],
      metadata: {
        page: 1,
        per_page: 10,
        page_count: 0,
        total_count: 0,
      },
    },
  });
  const [ticketId, setTicketId] = useState<string>();
  const [orderTicketId, setOrderTicketId] = useState<string>();
  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);

  const searchQuery = useRef({
    pic: "",
    project_name: "",
    file_name: "",
    estimation_number: "",
    status: "",
  });

  const [isCheck, setIsCheck] = useState(false);

  const fetchData = async () => {
    setLoading(true);
    const url = "/development/api/dashboard/orders";

    let queryString = "";
    for (const key in searchQuery.current) {
      if (searchQuery.current[key as keyof typeof searchQuery.current]) {
        queryString += queryString !== "" ? "&" : "?";
        queryString += `${key}=${
          searchQuery.current[key as keyof typeof searchQuery.current]
        }`;
      }
    }
    await axiosConfig.get("/development/api/jobs/check-due-dates");
    const response = await axiosConfig.get(`${url}${queryString}`);
    setData(response.data);
    setLoading(false);
  };

  const handleSearch = () => {
    setSearchParams((params) => {
      for (const key in searchQuery.current) {
        if (searchQuery.current[key as keyof typeof searchQuery.current]) {
          params.set(
            key,
            String(searchQuery.current[key as keyof typeof searchQuery.current])
          );
        } else {
          params.delete(key);
        }
      }
      return params;
    });
  };

  useEffect(() => {
    searchQuery.current = {
      pic: searchParams.get("pic") || "",
      project_name: searchParams.get("project_name") || "",
      file_name: searchParams.get("file_name") || "",
      estimation_number: searchParams.get("estimation_number") || "",
      status: searchParams.get("status") || "",
    };

    if (searchParams.get("status") === "4,9,17") {
      setIsCheck(true);
    } else {
      setIsCheck(false);
    }

    fetchData();
  }, [searchParams]);

  const handleListItemClick = async (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    setTicketId(undefined);
  };

  const [ticketIdSubDialog, setTicketIdSubDialog] = useState<string>();

  const allTicketIds = useMemo(() => {
    return data.body.data
      .map((row) =>
        row.tickets
          .filter(
            (ticket) => ticket.subcontractor_id === null && ticket.id !== null
          )
          .map((ticket) => ticket.id)
      )
      .flat();
  }, [data]);

  return (
    <>
      <div className=" flex flex-col">
        <Header pageTitle="自社ダッシュボード"></Header>
        <Grid container sx={{ height: "90vh" }} className="pb-6">
          <Grid item xs={1.5} className="py-2 bg-gray-100 h-full">
            <List component="nav" sx={{ color: "#3E6EB4" }}>
              <ListItemButton
                selected={selectedIndex === 0}
                onClick={(event) => handleListItemClick(event, 0)}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#3E6EB4",
                    color: "#fff",
                  },
                }}
              >
                <ListAltIcon className="mr-4" />
                <ListItemText primary="案件一覧" />
              </ListItemButton>

              <ListItemButton
                selected={selectedIndex === 1}
                onClick={(event) => handleListItemClick(event, 1)}
                sx={{
                  "&.Mui-selected": {
                    backgroundColor: "#3E6EB4",
                    color: "#fff",
                  },
                }}
              >
                <AnalyticsIcon className="mr-4" />
                <ListItemText primary="ガントチャート" />
              </ListItemButton>
            </List>
          </Grid>
          <Grid
            item
            xs={10.5}
            className="h-full pb-6 pt-4 px-4"
            sx={{ overflowX: "hidden", overflowY: "scroll" }}
          >
            {selectedIndex === 0 ? (
              <>
                <Card
                  className="mx-auto w-full"
                  sx={{ border: 1, borderColor: "grey.300" }}
                >
                  <CardHeader
                    sx={{ bgcolor: "#F5F5F5", paddingY: "5px" }}
                    subheader={
                      <Button
                        variant="text"
                        sx={{ padding: 0, color: "black" }}
                        disableRipple={true}
                        onClick={() => setShowSearchContent(!showSearchContent)}
                      >
                        絞り込み
                      </Button>
                    }
                  />
                  {showSearchContent && (
                    <CardContent sx={{ paddingY: "5px" }}>
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <h3>顧客名</h3>
                          <Autocomplete
                            freeSolo
                            className="mt-1"
                            size="small"
                            disableClearable
                            renderInput={(params) => <TextField {...params} />}
                            options={[
                              ...new Set(
                                data.body.data.map(
                                  (row: DataOrder) => row.order.pic
                                )
                              ),
                            ]}
                            value={searchQuery.current.pic}
                            onInputChange={(event, value) => {
                              searchQuery.current.pic = value;
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <h3>案件名</h3>
                          <Autocomplete
                            freeSolo
                            className="mt-1"
                            size="small"
                            disableClearable
                            renderInput={(params) => <TextField {...params} />}
                            options={[
                              ...new Set(
                                data.body.data.map(
                                  (row: DataOrder) => row.order.project_name
                                )
                              ),
                            ]}
                            value={searchQuery.current.project_name}
                            onInputChange={(event, value) => {
                              searchQuery.current.project_name = value;
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <h3>ファイル名</h3>
                          <Autocomplete
                            freeSolo
                            className="mt-1"
                            size="small"
                            disableClearable
                            renderInput={(params) => <TextField {...params} />}
                            options={[
                              ...new Set(
                                data.body.data
                                  .map((row) =>
                                    row.tickets.map(
                                      (ticket) => ticket.file_name
                                    )
                                  )
                                  .flat()
                              ),
                            ]}
                            value={searchQuery.current.file_name}
                            onInputChange={(_, value) =>
                              (searchQuery.current.file_name = value)
                            }
                            getOptionLabel={(option) => option || ""}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <h3>見積番号</h3>
                          <Autocomplete
                            freeSolo
                            className="mt-1"
                            size="small"
                            disableClearable
                            renderInput={(params) => <TextField {...params} />}
                            options={[
                              ...new Set(
                                data.body.data.map(
                                  (row: DataOrder) =>
                                    row.order.estimation_number
                                )
                              ),
                            ]}
                            value={searchQuery.current.estimation_number}
                            onInputChange={(event, value) => {
                              searchQuery.current.estimation_number = value;
                            }}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <h3>受注拒否のみ表示</h3>
                            <Checkbox
                              checked={isCheck}
                              onChange={(event) => {
                                if (event.target.checked) {
                                  searchQuery.current.status = "4,9,17";
                                } else {
                                  searchQuery.current.status = "";
                                }
                                setIsCheck(event.target.checked);
                              }}
                            />
                          </div>
                        </Grid>
                      </Grid>
                      <Stack className="mt-1">
                        <div className="ml-auto">
                          <Button
                            variant="contained"
                            sx={{ paddingX: "30px" }}
                            onClick={() => handleSearch()}
                          >
                            検索
                          </Button>
                        </div>
                      </Stack>
                    </CardContent>
                  )}
                </Card>
                <Card
                  className="mx-auto w-full mt-4"
                  sx={{ width: "100%", border: 1, borderColor: "grey.300" }}
                >
                  <CardHeader
                    sx={{ bgcolor: "#F5F5F5", paddingY: "10px" }}
                    subheader={"案件リスト"}
                    action={
                      !loading &&
                      data.body.data.length > 0 && (
                        <AssignAllTicketsButton
                          ticketIds={allTicketIds}
                          refreshData={fetchData}
                        />
                      )
                    }
                  ></CardHeader>
                  <CardContent>
                    <TableContainer component={Paper}>
                      <Table aria-label="collapsible table" size="small">
                        {loading && (
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              paddingY: 10,
                            }}
                          >
                            <CircularProgress
                              sx={{ marginLeft: "auto", marginRight: "auto" }}
                            />
                          </Box>
                        )}
                        {!loading && data.body.data.length === 0 && (
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              paddingY: 10,
                            }}
                          >
                            <p
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                              }}
                            >
                              データがありません。
                            </p>
                          </Box>
                        )}
                        {!loading && data.body.data.length > 0 && (
                          <TableBody>
                            {data.body.data.map((row: DataOrder) => (
                              <Row
                                key={row.order.id}
                                row={row}
                                setTicketId={setTicketId}
                                setTicketIdSubDialog={setTicketIdSubDialog}
                                fetchData={fetchData}
                                setOrderTicketId={setOrderTicketId}
                              />
                            ))}
                          </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </CardContent>
                </Card>
              </>
            ) : (
              selectedIndex === 1 && <GanttChart />
            )}
          </Grid>
        </Grid>
        <Footer></Footer>
      </div>
      <SelectSubcontractorDialog
        open={ticketIdSubDialog !== undefined}
        onClose={() => setTicketIdSubDialog(undefined)}
        ticketId={ticketIdSubDialog}
        refreshData={fetchData}
      />
      <Dialog
        open={!!ticketId}
        onClose={() => setTicketId(undefined)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>チケット詳細</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setTicketId(undefined)}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <TicketDetail ticketId={ticketId} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={!!orderTicketId}
        onClose={() => setOrderTicketId(undefined)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>注文詳細</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOrderTicketId(undefined)}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <OrderDetails orderTicketId={orderTicketId} />
        </DialogContent>
      </Dialog>
    </>
  );
};

interface SearchInput {
  fullname: string;
  username: string;
  [key: string]: string;
}

function SelectSubcontractorDialog({
  open,
  onClose,
  ticketId,
  refreshData,
}: {
  open: boolean;
  onClose: () => void;
  ticketId?: string;
  refreshData: () => {};
}) {
  const [subcontractorId, setSubcontractorId] = useState<string>();

  const columns: GridColDef[] = [
    {
      field: "select",
      headerName: "",
      flex: 0.1,
      sortable: false,
      renderCell: (params) => (
        <Checkbox
          color="primary"
          checked={subcontractorId === params.row.id}
          onChange={(e) => {
            setSubcontractorId(params.row.id);
          }}
        />
      ),
    },
    {
      field: "id",
      headerName: "ID",
      flex: 0.2,
    },
    {
      field: "username",
      headerName: "ログインID",
      flex: 0.3,
    },
    {
      field: "fullname",
      headerName: "氏名",
      flex: 0.3,
    },

    {
      field: "email",
      headerName: "メールアドレス",
      flex: 0.3,
    },
    {
      field: "phone_number",
      headerName: "電話番号",
      flex: 0.3,
    },
  ];
  const [data, setData] = useState([]);
  const [searchInput, setSearchInput] = useState<Partial<SearchInput>>({
    fullname: "",
    username: "",
  });
  const [fullnameList, setFullnameList] = useState<string[]>([]);
  const [usernameList, setUsernameList] = useState<string[]>([]);

  const fetchData = async (search = "") => {
    let url = "/development/api/users/subcontractor";
    if (search !== "") {
      url += `?${search}`;
    }
    await axiosConfig.get(url).then((res) => {
      setFullnameList(res.data.data.map((item: any) => item.fullname));
      setUsernameList(res.data.data.map((item: any) => item.username));
      setData(res.data.data);
    });
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean>();

  const handleChangeSearchInput = (value: string, field: string) => {
    setSearchInput((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const handleSearch = async () => {
    let searchUrl = "";
    const keys = Object.keys(searchInput);
    keys.forEach((key) => {
      if (searchInput[key] !== "") {
        if (searchUrl.length === 0) searchUrl += `${key}=${searchInput[key]}`;
        else searchUrl += `&${key}=${searchInput[key]}`;
      }
    });
    await fetchData(searchUrl);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onCloseDialog = () => {
    setSubcontractorId(undefined);
    if (searchInput.fullname || searchInput.username) {
      setSearchInput({
        fullname: "",
        username: "",
      });
      fetchData();
    }
    onClose();
  };

  const assignSubcontractor = async ({
    subcontractorId,
    ticketId,
  }: {
    subcontractorId: string;
    ticketId: string;
  }) => {
    setIsLoading(true);
    try {
      await axiosConfig.post(`/development/api/dashboard/assign-tickets`, {
        ticket_ids: [ticketId],
        subcontractor_id: subcontractorId,
      });
      setIsSuccess(true);
      refreshData();
    } catch (error) {
      console.error(error);
      setIsSuccess(false);
    }
  };

  return (
    <>
      <LoadingDialog
        isOpen={isLoading}
        message={"処理中"}
        isSuccess={isSuccess}
        onClose={() => {
          setIsLoading(false);
          setTimeout(() => {
            setIsSuccess(undefined);
          }, 1000);
        }}
      />
      <Dialog
        onClose={() => {
          onCloseDialog();
        }}
        aria-labelledby="select-subcontractor-dialog"
        open={open}
        maxWidth={"md"}
        fullWidth={true}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="select-subcontractor-dialog">
          協力会社選択
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => {
            onCloseDialog();
          }}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <Card className="mx-auto" sx={{ border: 1, borderColor: "grey.300" }}>
            <CardHeader sx={{ bgcolor: "#F5F5F5", paddingY: "5px" }} />
            <CardContent sx={{ paddingY: "5px" }}>
              <Grid container spacing={2}>
                <Grid item xs={2}>
                  <h3>氏名</h3>
                  <Autocomplete
                    freeSolo
                    className="mt-1"
                    size="small"
                    disableClearable
                    renderInput={(params) => <TextField {...params} />}
                    options={fullnameList}
                    value={searchInput.fullname}
                    onInputChange={(e, value) =>
                      handleChangeSearchInput(value, "fullname")
                    }
                  />
                </Grid>
                <Grid item xs={2}>
                  <h3>ログインID</h3>
                  <Autocomplete
                    freeSolo
                    className="mt-1"
                    size="small"
                    disableClearable
                    renderInput={(params) => <TextField {...params} />}
                    options={usernameList}
                    value={searchInput.username}
                    onInputChange={(e, value) =>
                      handleChangeSearchInput(value, "username")
                    }
                  />
                </Grid>
              </Grid>
              <Stack className="mt-2">
                <div className="ml-auto">
                  <Button variant="contained" onClick={handleSearch}>
                    検索
                  </Button>
                </div>
              </Stack>
            </CardContent>
          </Card>
          <Card
            className="mx-auto mt-5 mb-4"
            sx={{ border: 1, borderColor: "grey.300" }}
          >
            <CardHeader sx={{ bgcolor: "#F5F5F5", padding: "12px" }} />
            <CardContent>
              <DataGrid
                localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                rows={data}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[10, 20, 30]}
                disableColumnMenu
                disableColumnSorting
                disableRowSelectionOnClick
              />
              {data.length === 0 && (
                <Typography variant="subtitle1" align="center" sx={{ mt: 2 }}>
                  データがありません
                </Typography>
              )}
            </CardContent>
          </Card>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            sx={{ paddingX: "30px" }}
            onClick={() => {
              assignSubcontractor({
                subcontractorId: subcontractorId!,
                ticketId: ticketId!,
              });
              onCloseDialog();
            }}
          >
            確認する
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

function AssignAllTicketsButton({
  ticketIds,
  refreshData,
}: {
  ticketIds: string[];
  refreshData: () => {};
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState<boolean>();

  const assignAllTickets = async () => {
    const url = "/development/api/dashboard/assign-tickets";
    setIsLoading(true);
    try {
      await axiosConfig.post(url, {
        ticket_ids: ticketIds,
      });
      setIsSuccess(true);
      refreshData();
    } catch (error) {
      console.error(error);
      setIsSuccess(false);
    }
  };

  return (
    <>
      <LoadingDialog
        isOpen={isLoading}
        message={"処理中"}
        isSuccess={isSuccess}
        onClose={() => {
          setIsLoading(false);
          setTimeout(() => {
            setIsSuccess(undefined);
          }, 1000);
        }}
      />
      <Button
        variant="contained"
        size="small"
        disableRipple
        onClick={assignAllTickets}
      >
        すべてのチケットを発注
      </Button>
    </>
  );
}

export default EmployeeDashboard;
