// filepath: /f:/splus/rakucadtrace-fe/src/components/Snackbar.tsx
import React from 'react';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { Alert, Snackbar } from '@mui/material';
import { hideSnackbar } from '../../features/snackbar/snackbarSlice';

const SnackbarComponent: React.FC = () => {
  const snackbar = useAppSelector((state) => state.snackbar);

  const dispatch = useAppDispatch();

  if (!snackbar.open) return null;

  return (
    <Snackbar
      key={`snackbar-${snackbar.message}`}
      open={snackbar.open}
      autoHideDuration={snackbar.autoHideDuration}
      ClickAwayListenerProps={{ onClickAway: () => null }}
      TransitionProps={{
        onExited: () => {
          dispatch(hideSnackbar());
        }
      }}
      onClose={() => {
        dispatch(hideSnackbar());
      }}
    >
      <Alert
        severity={snackbar.type}
        variant='filled'
        sx={{
          width: '100%',
        }}
        onClose={() => {
          dispatch(hideSnackbar());
        }}
      >
        {snackbar.message}
      </Alert>
    </Snackbar>
  );
};

export default SnackbarComponent;