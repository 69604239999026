import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import axiosConfig from "../../axiosConfig";
import React from "react";
import { TicketStatus } from "../../common/constants";

export default function SubcontractorUploadFileDialog({
  open,
  onClose,
  ticketId,
  refreshData,
}: {
  open: boolean;
  onClose: () => void;
  ticketId: string;
  refreshData: () => void;
}) {
  const [loading, setLoading] = React.useState(false);
  const [imageName, setImageName] = React.useState("");
  const handleCloseDialog = () => {
    onClose();
    setImageName("");
  }

  return (
    <Dialog open={open} onClose={handleCloseDialog}>
      <DialogTitle>ファイルをアップロード</DialogTitle>
      <DialogContent dividers>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
        >
          <input
            type="file"
            accept="image/*"
            onChange={(e) => {
              const file = e.target.files?.[0];
              if (file) {
                setImageName(file.name);
                const reader = new FileReader();
                reader.onload = (event) => {
                  const imgElement = document.getElementById("uploadedImage") as HTMLImageElement;
                  if (imgElement) {
                    imgElement.src = event.target?.result as string;
                  }
                };
                reader.readAsDataURL(file);
              }
            }}
            style={{ display: "none" }}
            id="upload-input"
          />
          <label htmlFor="upload-input">
            <Button variant="contained" component="span">
              ファイルを選択
            </Button>
          </label>
          <span style={{ marginLeft: "10px" }}>
            {imageName || "ファイルが選択されていません"}
          </span>
        </div>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          marginTop: "20px"
        }}>
          <img id="uploadedImage" alt="アップロードされた画像" style={{ maxWidth: "100%", maxHeight: "300px" }} />
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={handleCloseDialog}>
          キャンセル
        </Button>
        <Button
          variant="contained"
          onClick={async () => {
            const imgElement = document.getElementById("uploadedImage") as HTMLImageElement;

            if (!imgElement.src) {
              return;
            }

            setLoading(true);
            const base64 = imgElement.src.split(",")[1];
            await axiosConfig.post("/development/api/sub-contractor/image/upload", {
              "image_name": imageName,
              "image_data": base64,
              "ticket_id": ticketId
            });
            await axiosConfig.patch(
              `/development/api/dashboard/tickets/${ticketId}`,
              {
                progress: 100,
                status: TicketStatus.indexOf("SUB_DELIVERED"),
              }
            );
            setLoading(false);

            refreshData();
            onClose();
          }}
        >
          {
            loading ? <CircularProgress size={20} sx={{
              color: "white"
            }} /> :
              "アップロード"
          }
        </Button>
      </DialogActions>
    </Dialog >
  );
}
