import {
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Card,
  CardHeader,
  Button,
  CardContent,
  Autocomplete,
  TextField,
  Stack,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Box,
  CircularProgress,
  ListItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Menu,
  Fade,
  MenuItem,
  ListItemIcon,
  Typography,
} from "@mui/material";
import Footer from "../../components/Footer/Footer";
import Header from "../../components/Header/Header";
import { useEffect, useMemo, useRef, useState } from "react";
import React from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Ticket,
  DataOrder,
  ResDataOrder,
  TicketStatus,
  displayStatusCustomer,
  displayStatusCompany,
  displayStatusSubcontractor,
} from "../../common/constants";
import axiosConfig from "../../axiosConfig";
import { useNavigate, useSearchParams } from "react-router-dom";
import { getformatDateFromISOFormat } from "../../components/Utils/utils";
import TicketDetail from "../TicketDetail";
import {
  Close,
  InfoOutlined,
  ListAlt,
  MoreVert,
  SaveAlt,
} from "@mui/icons-material";
import DownloadImageDialog from "./DownloadImageDialog";
import ConfirmDialog, { ConfirmDialogType } from "./ConfirmDialog";
import OrderDetails from "./OrderDetails";

function Row(props: {
  row: DataOrder;
  setTicketId: React.Dispatch<React.SetStateAction<string | undefined>>;
  fetchData: () => {};
  setOrderTicketId: React.Dispatch<React.SetStateAction<string | undefined>>;
}) {
  const { row, setTicketId } = props;
  const [expand, setExpand] = React.useState(false);
  const [selectedTicket, setSelectedTicket] = React.useState<Ticket>();
  const [imageData, setImageData] = React.useState<string | null>(null);
  const [imageName, setImageName] = React.useState<string | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [openDownloadDialog, setOpenDownloadDialog] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const openMenu = Boolean(anchorEl);
  const [isAccept, setIsAccept] = React.useState<boolean | null>(null);
  const [openAcceptOrRejectDialog, setOpenAcceptOrRejectDialog] =
    React.useState(false);
  const [isRefresh, setIsRefresh] = React.useState(false);

  const handleDownloadInitialFileClick = async () => {
    setImageData(null);
    setOpenDownloadDialog(true);
    setLoading(true);

    try {
      const response = await axiosConfig({
        method: "get",
        url: `/development/api/dashboard/download-drawing/${selectedTicket?.id}`,
      });

      // Store image data & filename in state
      setImageData(response.data);
      setImageName(selectedTicket!.file_name);
    } catch (error) {
      console.error("Error downloading file", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadResultFileClick = async () => {
    setImageData(null);
    setOpenDownloadDialog(true);
    setLoading(true);

    try {
      const response = await axiosConfig({
        method: "get",
        url: `/development/api/sub-contractor/image/download/${selectedTicket?.id}`,
      });

      // Store image data & filename in state
      setImageData(response.data.file_content);
      setImageName(response.data.file_name);

      if (
        TicketStatus.indexOf("COMPANY_DELIVERED") === selectedTicket?.status
      ) {
        await axiosConfig.patch(
          `/development/api/dashboard/tickets/${selectedTicket?.id}`,
          {
            status: TicketStatus.indexOf("CUSTOMER_INSPECTION_ONGOING"),
          }
        );
        setIsRefresh(true);
      }
    } catch (error) {
      console.error("Error downloading file", error);
    } finally {
      setLoading(false);
    }
  };

  const handleCloseDialog = () => {
    setOpenDownloadDialog(false);
  };

  const handleDownloadFromDialog = () => {
    if (imageData) {
      // Decode base64 string
      const byteCharacters = atob(imageData);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);

      // Create a Blob object with the correct MIME type
      const blob = new Blob([byteArray], {
        type: `image/${imageName?.split(".").pop() || "png"}`,
      });

      // Create a URL for the Blob object
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", imageName || "file");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell sx={{ width: "30px" }}>
          {row.tickets.length > 0 && (
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setExpand(!expand)}
            >
              {expand ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </TableCell>
        <TableCell component="th" scope="row">
          {row.order.estimation_number}
        </TableCell>
        <TableCell
          sx={{
            width: "30%",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          {row.order.project_name}
        </TableCell>
        <TableCell>
          <div style={{ display: "flex" }}>
            <Button
              variant="contained"
              size="small"
              disableRipple
              sx={{ marginLeft: "auto" }}
              onClick={() => {
                props.setOrderTicketId(row.tickets[0].id);
              }}
            >
              詳細
            </Button>
          </div>
        </TableCell>
      </TableRow>

      {row.tickets.length > 0 && (
        <TableRow>
          <TableCell colSpan={12} sx={{ padding: 0 }}>
            <Collapse in={expand} timeout="auto" unmountOnExit>
              <Table
                size="small"
                sx={{
                  width: "100%",
                  tableLayout: "fixed",
                }}
              >
                <TableBody>
                  {row.tickets
                    .filter((ticket) => ticket.id != null)
                    .map((ticketRow: Ticket) => (
                      <TableRow key={ticketRow.id}>
                        <TableCell
                          sx={{
                            width: "5%",
                          }}
                        />
                        <TableCell
                          align="left"
                          sx={{
                            width: "30%",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          }}
                        >
                          {ticketRow.file_name}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            width: "15%",
                          }}
                        >
                          {getformatDateFromISOFormat(ticketRow.delivery_at)}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{
                            width: "20%",
                          }}
                        >
                          {displayStatusCustomer[
                            TicketStatus[ticketRow.status]
                          ] ??
                            displayStatusCompany[
                            TicketStatus[ticketRow.status]
                            ] ??
                            displayStatusSubcontractor[
                            TicketStatus[ticketRow.status]
                            ]}
                        </TableCell>
                        <TableCell>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                            }}
                          >
                            {TicketStatus[ticketRow.status] ==
                              "CUSTOMER_INSPECTION_ONGOING" && (
                                <Button
                                  variant="contained"
                                  size="small"
                                  onClick={() => {
                                    setSelectedTicket(ticketRow);
                                    setIsAccept(true);
                                    setOpenAcceptOrRejectDialog(true);
                                  }}
                                >
                                  受入完了
                                </Button>
                              )}
                            {TicketStatus[ticketRow.status] ==
                              "CUSTOMER_INSPECTION_ONGOING" && (
                                <Button
                                  variant="contained"
                                  size="small"
                                  color="error"
                                  sx={{
                                    marginLeft: "20px",
                                  }}
                                  onClick={() => {
                                    setSelectedTicket(ticketRow);
                                    setIsAccept(false);
                                    setOpenAcceptOrRejectDialog(true);
                                  }}
                                >
                                  受入拒否
                                </Button>
                              )}
                            <IconButton
                              id="fade-button"
                              aria-controls={openMenu ? "fade-menu" : undefined}
                              aria-haspopup="true"
                              aria-expanded={openMenu ? "true" : undefined}
                              onClick={(
                                event: React.MouseEvent<HTMLElement>
                              ) => {
                                setAnchorEl(event.currentTarget);
                                setSelectedTicket(ticketRow);
                              }}
                              sx={{
                                marginLeft: "20px",
                              }}
                            >
                              <MoreVert />
                            </IconButton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
      <DownloadImageDialog
        open={openDownloadDialog}
        onClose={() => {
          handleCloseDialog();
          if (isRefresh) {
            props.fetchData();
            setIsRefresh(false);
          }
        }}
        imageUrl={imageData || ""}
        imageType={imageName?.split(".").pop() || "png"}
        onDownload={handleDownloadFromDialog}
        loading={loading}
      />
      <ConfirmDialog
        dialogType={
          isAccept == true
            ? ConfirmDialogType.customerAcceptEmployee
            : ConfirmDialogType.customerRejectEmployee
        }
        open={openAcceptOrRejectDialog}
        onClose={() => {
          setOpenAcceptOrRejectDialog(false);
        }}
        onConfirm={async () => {
          if (selectedTicket) {
            await axiosConfig.patch(
              `/development/api/dashboard/tickets/${selectedTicket.id}`,
              {
                status: isAccept
                  ? TicketStatus.indexOf("CUSTOMER_INSPECTION_COMPLETED")
                  : TicketStatus.indexOf("CUSTOMER_DELIVERY_REJECTED"),
              }
            );
            props.fetchData();
          }
          setOpenAcceptOrRejectDialog(false);
        }}
        estimationNumber={row.order.estimation_number}
        fileName={selectedTicket?.file_name || ""}
      />
      <Menu
        id="fade-menu"
        MenuListProps={{
          "aria-labelledby": "fade-button",
        }}
        anchorEl={anchorEl}
        open={openMenu}
        onClose={() => {
          setAnchorEl(null);
        }}
        TransitionComponent={Fade}
      >
        <MenuItem
          onClick={() => {
            handleDownloadInitialFileClick();
            setImageName(selectedTicket!.file_name);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <SaveAlt
              sx={{
                color: "#4CAF50",
              }}
            />
          </ListItemIcon>
          <Typography
            variant="inherit"
            sx={{
              color: "#4CAF50",
            }}
          >
            ファイル
          </Typography>
        </MenuItem>
        <MenuItem
          disabled={TicketStatus.indexOf("COMPANY_DELIVERED") !== selectedTicket?.status
            && TicketStatus.indexOf("CUSTOMER_DELIVERY_REJECTED") !== selectedTicket?.status
            && TicketStatus.indexOf("CUSTOMER_INSPECTION_ONGOING") !== selectedTicket?.status
            && TicketStatus.indexOf("CUSTOMER_INSPECTION_COMPLETED") !== selectedTicket?.status
          }
          onClick={() => {
            handleDownloadResultFileClick();
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <SaveAlt
              sx={{
                color: "#4CAF50",
              }}
            />
          </ListItemIcon>
          <Typography
            variant="inherit"
            sx={{
              color: "#4CAF50",
            }}
          >
            納品物
          </Typography>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setTicketId(selectedTicket?.id);
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <InfoOutlined
              sx={{
                color: "#3964B1",
              }}
            />
          </ListItemIcon>
          <Typography
            variant="inherit"
            sx={{
              color: "#3964B1",
            }}
          >
            詳細
          </Typography>
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
}

const CustomerDashboard: React.FC = () => {
  const [showSearchContent, setShowSearchContent] = useState(true);
  const [data, setData] = useState<ResDataOrder>({
    message: "",
    body: {
      data: [],
      metadata: {
        page: 1,
        per_page: 10,
        page_count: 0,
        total_count: 0,
      },
    },
  });

  const [loading, setLoading] = useState(false);

  const [ticketId, setTicketId] = useState<string>();

  const [orderTicketId, setOrderTicketId] = useState<string>();

  const [searchParams, setSearchParams] = useSearchParams();

  const searchQuery = useRef({
    project_name: "",
    file_name: "",
    estimation_number: "",
    status: "-1",
  });

  const fetchData = async () => {
    setLoading(true);
    const url = "/development/api/dashboard/orders";

    if (searchParams.get("status") === "-2") {
      searchQuery.current.status = "2,3,5,6,7,8,9,10,11,12,14";
    }

    let queryString = "";
    for (const key in searchQuery.current) {
      if (searchQuery.current[key as keyof typeof searchQuery.current]) {
        queryString += queryString !== "" ? "&" : "?";
        queryString += `${key}=${searchQuery.current[key as keyof typeof searchQuery.current]
          }`;
      }
    }

    const response = await axiosConfig.get(`${url}${queryString}`);
    setLoading(false);
    setData(response.data);
  };

  const handleSearch = () => {
    setSearchParams((params) => {
      for (const key in searchQuery.current) {
        if (searchQuery.current[key as keyof typeof searchQuery.current]) {
          params.set(
            key,
            String(searchQuery.current[key as keyof typeof searchQuery.current])
          );
        } else {
          params.delete(key);
        }
      }
      return params;
    });
  };

  const autocompleteOptions = useMemo(() => {
    const uniqueValues = new Set();
    return [
      { key: "-1", value: "すべて" },
      ...Object.entries(displayStatusCustomer)
        .map(([key, value]) => ({
          key: value != "作業中" ? TicketStatus.indexOf(key).toString() : "-2",
          value: value,
        }))
        .filter((option) => {
          if (uniqueValues.has(option.value)) {
            return false;
          }
          uniqueValues.add(option.value);
          return true;
        }),
    ];
  }, []);

  const [status, setStatus] = useState("-1");

  const navigate = useNavigate();

  useEffect(() => {
    searchQuery.current = {
      project_name: searchParams.get("project_name") || "",
      file_name: searchParams.get("file_name") || "",
      estimation_number: searchParams.get("estimation_number") || "",
      status:
        searchParams.get("status") === "-1"
          ? ""
          : searchParams.get("status") || "",
    };
    setStatus(searchQuery.current.status ?? "-1");

    fetchData();
  }, [searchParams]);

  return (
    <div className=" flex flex-col">
      <Header pageTitle="顧客ダッシュボード"></Header>
      <Grid container sx={{ height: "90vh" }} className="pb-6">
        <Grid item xs={1.5} className="py-2 bg-gray-100 h-full">
          <List component="nav" sx={{ color: "#3E6EB4" }}>
            <ListItem
              sx={{
                pb: 2,
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  margin: "auto",
                  backgroundColor: "white",
                  "&:hover": {
                    backgroundColor: "white",
                    color: "#3E6EB4",
                  },
                }}
                onClick={() => {
                  navigate("/");
                }}
              >
                <ListItemText primary={"見積もりを作成"} />
              </Button>
            </ListItem>

            <ListItemButton
              selected={true}
              onClick={(event) => {
                handleSearch();
                setTicketId(undefined);
              }}
              sx={{
                "&.Mui-selected": {
                  backgroundColor: "#3E6EB4",
                  color: "#fff",
                },
              }}
            >
              <ListAlt className="mr-4" />
              <ListItemText primary={"案件一覧"} />
            </ListItemButton>
          </List>
        </Grid>
        <Grid
          item
          xs={10.5}
          className="h-full pb-6 pt-4 px-4"
          sx={{ overflowX: "hidden", overflowY: "scroll" }}
        >
          <Card
            className="mx-auto w-full"
            sx={{ border: 1, borderColor: "grey.300" }}
          >
            <CardHeader
              sx={{ bgcolor: "#F5F5F5", paddingY: "5px" }}
              subheader={
                <Button
                  variant="text"
                  sx={{ padding: 0, color: "black" }}
                  disableRipple={true}
                  onClick={() => setShowSearchContent(!showSearchContent)}
                >
                  絞り込み
                </Button>
              }
            />
            {showSearchContent && (
              <CardContent sx={{ paddingY: "5px" }}>
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <h3>案件名</h3>
                    <Autocomplete
                      freeSolo
                      className="mt-1"
                      size="small"
                      disableClearable
                      renderInput={(params) => <TextField {...params} />}
                      options={[
                        ...new Set(
                          data.body.data.map((row) => row.order.project_name)
                        ),
                      ]}
                      value={searchQuery.current.project_name}
                      onInputChange={(event, value) =>
                        (searchQuery.current.project_name = value)
                      }
                    />
                  </Grid>
                  <Grid item xs={2}>
                    <h3>ファイル名</h3>
                    <Autocomplete
                      freeSolo
                      className="mt-1"
                      size="small"
                      disableClearable
                      renderInput={(params) => <TextField {...params} />}
                      options={[
                        ...new Set(
                          data.body.data
                            .map((row) =>
                              row.tickets.map((ticket) => ticket.file_name)
                            )
                            .flat()
                        ),
                      ]}
                      getOptionLabel={(option) => option || ""}
                      value={searchQuery.current.file_name}
                      onInputChange={(event, value) =>
                        (searchQuery.current.file_name = value)
                      }
                    />
                  </Grid>
                  {/* <Grid item xs={2}>
                        <h3>見積番号</h3>
                        <Autocomplete
                          freeSolo
                          className="mt-1"
                          size="small"
                          disableClearable
                          renderInput={(params) => <TextField {...params} />}
                          options={[
                            ...new Set(
                              data.body.data.map(
                                (row) => row.order.estimation_number
                              )
                            ),
                          ]}
                          value={searchQuery.current.estimation_number}
                          onInputChange={(event, value) =>
                            (searchQuery.current.estimation_number = value)
                          }
                        />
                      </Grid> */}
                  <Grid item xs={2}>
                    <h3>ステータス</h3>
                    <Autocomplete
                      className="mt-1"
                      size="small"
                      onKeyDown={(e) => e.preventDefault()}
                      disableClearable
                      options={autocompleteOptions}
                      getOptionLabel={(option) => option.value ?? ""}
                      value={{
                        key: status,
                        value:
                          status === "-2"
                            ? "作業中"
                            : displayStatusCustomer[
                            TicketStatus[parseInt(status)]
                            ] ?? "すべて",
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.key === value.key
                      }
                      renderInput={(params) => <TextField {...params} />}
                      onChange={(_, value) => {
                        searchQuery.current.status = value?.key ?? "-1";
                        setStatus(value?.key ?? "-1");
                      }}
                    />
                  </Grid>
                </Grid>
                <Stack className="mt-1">
                  <div className="ml-auto">
                    <Button
                      variant="contained"
                      sx={{ paddingX: "30px" }}
                      onClick={() => handleSearch()}
                    >
                      検索
                    </Button>
                  </div>
                </Stack>
              </CardContent>
            )}
          </Card>
          <Card
            className="mx-auto w-full mt-4"
            sx={{ width: "100%", border: 1, borderColor: "grey.300" }}
          >
            <CardHeader
              sx={{ bgcolor: "#F5F5F5", paddingY: "10px" }}
              subheader={"案件リスト"}
            ></CardHeader>
            <CardContent>
              <TableContainer component={Paper}>
                <Table aria-label="collapsible table" size="small">
                  {loading && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        paddingY: 10,
                      }}
                    >
                      <CircularProgress
                        sx={{ marginLeft: "auto", marginRight: "auto" }}
                      />
                    </Box>
                  )}
                  <TableBody>
                    {!loading &&
                      data?.body.data.map((row: DataOrder) => (
                        <Row
                          key={row.order.id}
                          row={row}
                          setTicketId={setTicketId}
                          fetchData={fetchData}
                          setOrderTicketId={setOrderTicketId}
                        />
                      ))}
                  </TableBody>
                  {!loading && data?.body.data.length <= 0 && (
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        paddingY: 10,
                      }}
                    >
                      <p
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                        }}
                      >
                        データがありません。
                      </p>
                    </Box>
                  )}
                </Table>
              </TableContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Footer></Footer>
      <Dialog
        open={!!ticketId}
        onClose={() => setTicketId(undefined)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>チケット詳細</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setTicketId(undefined)}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <TicketDetail ticketId={ticketId} />
        </DialogContent>
      </Dialog>

      <Dialog
        open={!!orderTicketId}
        onClose={() => setOrderTicketId(undefined)}
        maxWidth="lg"
        fullWidth
      >
        <DialogTitle>注文詳細</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => setOrderTicketId(undefined)}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500],
          })}
        >
          <Close />
        </IconButton>
        <DialogContent dividers>
          <OrderDetails orderTicketId={orderTicketId} />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CustomerDashboard;
