import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface SnackbarState {
  message: string;
  type: "success" | "error" | "info" | "warning";
  open: boolean;
  autoHideDuration?: number;
}

const initialState: SnackbarState = {
  message: "",
  type: "info",
  open: false,
};

export const snackbarSlice = createSlice({
  name: "snackbar",
  initialState,
  reducers: {
    showSnackbar: (state, action: PayloadAction<Omit<SnackbarState, 'open'>>) => {
      state.message = action.payload.message;
      state.type = action.payload.type;
      state.open = true;
      state.autoHideDuration = action.payload.autoHideDuration || 6000;
    },
    hideSnackbar: (state) => {
      state.open = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { showSnackbar, hideSnackbar } = snackbarSlice.actions;

export const getSnackbarState = (state: { snackbar: SnackbarState }) => state.snackbar;

export default snackbarSlice.reducer;