import { useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import { useNavigate, useParams } from "react-router-dom";
import axiosConfig from "../../axiosConfig";
import { Button } from "@mui/material";

export default function ActiveAccount() {
  const navigate = useNavigate();

  const params = useParams();

  const [successMessage, setSuccessMessage] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>("");

  const handleActiveCode = async () => {
    try {
      const res = await axiosConfig.post("/development/api/auth/activate-code", {
        code: params.activeCode,
      });

      if (res.status === 200) {
        setSuccessMessage("アカウントの有効化が完了しました");
        setErrorMessage("");
      } else {
        setErrorMessage("アカウントの有効化に失敗しました");
        setSuccessMessage("");
      }
    } catch (error) {
      setErrorMessage("アカウントの有効化に失敗しました");
      setSuccessMessage("");
    }
  };

  useEffect(() => {
    const activeCode = params.activeCode;
    console.log(activeCode);
    handleActiveCode();
  }, []);

  return (
    <div className="bg-gray-100 flex flex-col" style={{ minHeight: "100vh" }}>
      <Header pageTitle="アカウントを有効化"></Header>
      <div className="my-auto flex">
        <div className="mx-auto w-full max-w-md">
          <div className="bg-white p-8 rounded-lg shadow-lg">
            <h2 className="text-2xl font-semibold text-center mb-4">アカウントを有効化</h2>
            {
              successMessage != "" ? <p className="text-center mb-4 text-green-500">{successMessage}</p> :
                errorMessage != "" ? <p className="text-center mb-4 text-red-500">{errorMessage}</p> :
                  <>
                    <p className="text-center mb-4">
                      アカウントを有効化しています
                      <span className="dot-animate">.</span>
                    </p>
                    <style>
                      {`
              @keyframes dot {
              0%, 20% {
              color: rgba(0,0,0,0);
              text-shadow:
              .25em 0 0 rgba(0,0,0,0),
              .5em 0 0 rgba(0,0,0,0);
              }
              40% {
              color: black;
              text-shadow:
              .25em 0 0 rgba(0,0,0,0),
              .5em 0 0 rgba(0,0,0,0);
              }
              60% {
              text-shadow:
              .25em 0 0 black,
              .5em 0 0 rgba(0,0,0,0);
              }
              80%, 100% {
              text-shadow:
              .25em 0 0 black,
              .5em 0 0 black;
              }
              }
              .dot-animate {
              animation: dot 1.5s infinite;
              }
            `}
                    </style>
                  </>}
            {
              successMessage != "" &&
              <Button
                variant="contained"
                className="w-full mt-4"
                onClick={() => {
                  navigate("/login", { replace: true });
                }}
              >
                ログイン
              </Button>
            }
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}