import {
  Card,
  CardContent,
  Stepper,
  Step,
  StepLabel,
  Typography,
  Skeleton,
  Button,
  Box,
  TextField,
  CircularProgress,
} from "@mui/material";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";
import TicketDetails from "../interfaces/ticket";
import axiosConfig from "../axiosConfig";
import { getformatDateFromISOFormat } from "../components/Utils/utils";
import { HttpError } from "../interfaces/error";
import ErrorComponent from "../components/Error/ErrorComponent";
import { useAppSelector } from "../app/hooks";
import { displayStatusCompany, displayStatusCustomer, displayStatusSubcontractor, stepperStatusCompany, stepperStatusCustomer, stepperStatusSubcontractor, TicketStatus } from "../common/constants";

const TicketDetail: React.FC<{
  ticketId?: string;
  refreshData?: () => void;
}> = ({ ticketId, refreshData }) => {
  const accountInfo = useAppSelector((state) => state.accountInfo);
  const [ticket, setTicket] = React.useState<TicketDetails | null>(null);
  const [error, setError] = React.useState<HttpError | null>(null);
  const { id } = useParams();
  const [displayStatus, setDisplayStatus] = React.useState<Partial<Record<string, string>>>(displayStatusCustomer);
  const [stepperStatus, setStepperStatus] = React.useState<Partial<Record<string, string>>>(stepperStatusCustomer);
  const [loading, setLoading] = React.useState(false);

  const fetchData = async () => {
    try {
      const response = await axiosConfig.get(
        `/development/api/dashboard/tickets/${ticketId ?? id}`
      );
      setTicket(response.data.body);
      setError(null);
    } catch (error: any) {
      setError({
        statusCode: error.response.status,
        message: error.response.data.detail,
      } as HttpError);
    }
  };

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (accountInfo.type === "customer") {
      setDisplayStatus(displayStatusCustomer);
      setStepperStatus(stepperStatusCustomer);
    } else if (accountInfo.type === "subcontractor") {
      setDisplayStatus(displayStatusSubcontractor);
      setStepperStatus(stepperStatusSubcontractor);
    } else {
      setDisplayStatus(displayStatusCompany);
      setStepperStatus(stepperStatusCompany);
    }
  }, [accountInfo]);

  const [progress, setProgress] = React.useState(ticket?.progress ?? 0);

  useEffect(() => {
    setProgress(ticket?.progress ?? 0);
  }, [ticket]);

  if (error) {
    return (
      <div className="flex flex-col">
        {id && <Header pageTitle="チケット詳細" />}
        <ErrorComponent statusCode={error.statusCode} message={error.message} />
        {id && <Footer />}
      </div>
    );
  }

  if (!ticket) {
    return (
      <div className="flex flex-col" style={{ marginBottom: "200px" }}>
        {id && <Header pageTitle="チケット詳細" />}
        <div
          style={{
            padding: "20px",
            width: ticketId ? "60vw" : "90vw",
            margin: "auto",
          }}
        >
          <Skeleton variant="rectangular" width="100%" height={50} />
          <Skeleton
            variant="text"
            width="60%"
            height={50}
            style={{ marginTop: "20px" }}
          />
          <Skeleton variant="text" width="40%" height={30} />
          <Skeleton variant="text" width="80%" height={30} />
          <Skeleton variant="text" width="90%" height={30} />
          <Skeleton variant="text" width="50%" height={30} />
          <Skeleton
            variant="text"
            width="60%"
            height={50}
            style={{ marginTop: "20px" }}
          />
          <Skeleton variant="text" width="60%" height={30} />
          <Skeleton variant="text" width="80%" height={30} />
          <Skeleton variant="text" width="90%" height={30} />
          <Skeleton variant="text" width="50%" height={30} />
          <Skeleton
            variant="text"
            width="60%"
            height={50}
            style={{ marginTop: "20px" }}
          />
          <Skeleton variant="text" width="60%" height={30} />
          <Skeleton variant="text" width="80%" height={30} />
        </div>
        {id && <Footer />}
      </div>
    );
  }

  return (
    <div className="flex flex-col" style={{ marginBottom: id ? "200px" : "0px" }}>
      {id && <Header pageTitle="チケット詳細" />}
      <div className="mx-auto" style={{ width: ticketId ? "60vw" : "90vw" }}>
        {id && <Button
          variant="contained"
          color="primary"
          onClick={() => {

            navigate(-1);

          }}
          sx={{ marginTop: "20px", marginBottom: "10px" }}
        >
          戻る
        </Button>}
      </div>
      <Stepper
        activeStep={
          accountInfo.type == "customer"
            ? displayStatus[TicketStatus[ticket.status]] === "作業中"
              ? 2
              : Object.values(stepperStatus).findIndex(status => status === displayStatus[TicketStatus[ticket.status]]) < 2
                ? Object.values(stepperStatus).findIndex(status => status === displayStatus[TicketStatus[ticket.status]])
                : Object.values(stepperStatus).findIndex(status => status === displayStatus[TicketStatus[ticket.status]]) - 10
            : Object.values(stepperStatus).findIndex(status => status === displayStatus[TicketStatus[ticket.status]])
        }
        alternativeLabel
        sx={{
          marginTop: "20px",
          marginBottom: "10px",
        }}
      >
        {Array.from(new Set(Object.values(stepperStatus))).map((status, index) => {
          return (
            <Step
              key={status}
              sx={{
                "& .MuiStepLabel-label.Mui-completed.MuiStepLabel-alternativeLabel":
                {
                  color: "primary.main",
                },
                "& .MuiStepLabel-label.Mui-active.MuiStepLabel-alternativeLabel":
                {
                  color: "primary.main",
                },
              }}
            >
              <StepLabel>{status}</StepLabel>
            </Step>
          );
        })}
      </Stepper>

      {/* {accountInfo.type === "subcontractor" && (
        <UpdateProgressCard
          ticket={ticket}
          width={ticketId ? "60vw" : "90vw"}
        />
      )} */}

      <InfoCard title="図面情報" width={ticketId ? "60vw" : "90vw"}>
        <Typography>
          <strong>チケットID:</strong> {ticket.ticket_id}
        </Typography>
        <Typography>
          <strong>図面ID:</strong> {ticket.drawing_id}
        </Typography>
        <Typography>
          <strong>外注先ID:</strong> {ticket.subcontractor_id}
        </Typography>
        <Typography>
          <strong>外注先名:</strong> {ticket.subcontractor_name}
        </Typography>
        <Typography>
          <strong>内容:</strong> {ticket.file_name}
        </Typography>
        <Typography>
          <strong>金額:</strong> {ticket.amount}
        </Typography>
        <Typography>
          <strong>状態:</strong>
          {" "}
          {
            displayStatus[TicketStatus[ticket.status]]
            ?? displayStatusCompany[TicketStatus[ticket.status]]
            ?? displayStatusSubcontractor[TicketStatus[ticket.status]]
            ?? displayStatusCustomer[TicketStatus[ticket.status]]
          }
        </Typography>
        <Typography sx={{
          display: "flex",
          alignItems: "center",
        }}>
          <strong style={{
            marginRight: "10px",
          }}>進捗:</strong>
          {
            (accountInfo.type === "subcontractor"
              && (ticket.status === TicketStatus.indexOf("SUB_ORDER_ACCEPTED")
                || ticket.status === TicketStatus.indexOf("SUB_INPROGRESS")
                || ticket.status === TicketStatus.indexOf("COMPANY_REDO_REQUESTED")
              ))
              ?
              <TextField
                select
                value={progress}
                SelectProps={{
                  native: true,
                }}
                variant="outlined"
                size="small"
                onChange={(e) => {
                  setProgress(parseInt(e.target.value));
                }}
              >
                {[0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100].map((value) => (
                  <option key={value} value={value}>
                    {value}
                  </option>
                ))}
              </TextField>
              : ticket.progress
          }
          <Typography sx={{
            marginLeft: "10px",
          }}>%</Typography>
        </Typography>
        <Typography>
          <strong>納期:</strong>{" "}
          {getformatDateFromISOFormat(ticket.delivery_at)}
        </Typography>
        {
          (accountInfo.type === "subcontractor"
            && (
              ticket.status === TicketStatus.indexOf("SUB_ORDER_ACCEPTED")
              || ticket.status === TicketStatus.indexOf("SUB_INPROGRESS")
              || ticket.status === TicketStatus.indexOf("COMPANY_REDO_REQUESTED")
            )
            && ticket.progress !== progress
          )
          && <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button
              variant="contained"
              color="primary"
              onClick={async () => {
                try {
                  setLoading(true);
                  await axiosConfig.patch(
                    `/development/api/dashboard/tickets/${ticket.ticket_id}`,
                    {
                      progress,
                      status: TicketStatus.indexOf("SUB_INPROGRESS"),
                    }
                  );
                  setLoading(false);
                  fetchData();
                  refreshData?.();
                } catch (error: any) {
                  console.error(error);
                }
              }
              }
            >
              {
                loading ? <CircularProgress size={20} sx={{
                  color: "white",
                }} />
                  : "確認"
              }
            </Button>
          </Box>
        }
      </InfoCard>

      <InfoCard title="注文情報" width={ticketId ? "60vw" : "90vw"}>
        <Typography>
          <strong>発注ID:</strong> {ticket.order_id}
        </Typography>
        <Typography>
          <strong>案件名:</strong> {ticket.project_name}
        </Typography>
        <Typography>
          <strong>見積番号:</strong> {ticket.estimation_number}
        </Typography>
        <Typography>
          <strong>作成日:</strong>{" "}
          {getformatDateFromISOFormat(ticket.created_at)}
        </Typography>
        <Typography>
          <strong>会社名:</strong> {ticket.company_name}
        </Typography>
        <Typography>
          <strong>住所:</strong> {ticket.address}
        </Typography>
        <Typography>
          <strong>住所2:</strong> {ticket.address2}
        </Typography>
        <Typography>
          <strong>郵便番号:</strong> {ticket.postal_code}
        </Typography>
        <Typography>
          <strong>Email:</strong> {ticket.email}
        </Typography>
        <Typography>
          <strong>電話番号:</strong> {ticket.phone_number}
        </Typography>
        <Typography>
          <strong>担当者のお名前:</strong> {ticket.pic}
        </Typography>
        <Typography>
          <strong>小計:</strong> {ticket.sub_total}
        </Typography>
        <Typography>
          <strong>消費税:</strong> {ticket.consumption_tax}
        </Typography>
        <Typography>
          <strong>合計:</strong> {ticket.total_amount}
        </Typography>
      </InfoCard>

      <InfoCard title="顧客情報" width={ticketId ? "60vw" : "90vw"}>
        <Typography>
          <strong>顧客ID:</strong> {ticket.customer_id}
        </Typography>
        <Typography>
          <strong>顧客名:</strong> {ticket.customer_name}
        </Typography>
      </InfoCard>

      {id && <Footer />}
    </div>
  );
};

const InfoCard: React.FC<{
  title: string;
  children: React.ReactNode;
  width?: string;
}> = ({ title, children, width }) => {
  return (
    <Card
      className="mx-auto mt-4"
      sx={{
        border: 1,
        borderColor: "grey.300",
        borderRadius: "10px",
        width: width ?? "90vw",
      }}
    >
      <CardContent
        sx={{
          "& > *": {
            marginBottom: 1,
          },
        }}
      >
        <Typography
          sx={{
            fontSize: 24,
            fontWeight: "bold",
            marginBottom: 1,
          }}
        >
          {title}
        </Typography>
        {children}
      </CardContent>
    </Card>
  );
};

// const UpdateProgressCard: React.FC<{
//   ticket: TicketDetails;
//   width?: string;
// }> = ({ ticket, width }) => {
//   const [progress, setProgress] = React.useState(ticket.progress);

//   return (
//     <Card
//       className="mx-auto mt-4"
//       sx={{
//         border: 1,
//         borderColor: "grey.300",
//         borderRadius: "10px",
//         width: width ?? "90vw",
//       }}
//     >
//       <CardContent
//         sx={{
//           "& > *": {
//             marginBottom: 1,
//           },
//         }}
//       >
//         <Typography
//           sx={{
//             fontSize: 24,
//             fontWeight: "bold",
//             marginBottom: 1,
//           }}
//         >
//           外注先作業中
//         </Typography>
//         <Box
//           sx={{
//             display: "flex",
//             alignItems: "center",
//           }}
//         >
//           <Typography>進捗: </Typography>
//           <TextField
//             variant="outlined"
//             size="small"
//             defaultValue={ticket.progress}
//             value={progress}
//             type="number"
//             inputProps={{
//               min: 0,
//               max: 100,
//             }}
//             sx={{
//               width: "80px",
//               marginLeft: "10px",
//               marginRight: "10px",
//             }}
//             onChange={(e) => {
//               let value = parseInt(e.target.value);
//               if (value < 0) {
//                 value = 0;
//               } else if (value > 100) {
//                 value = 100;
//               }
//               setProgress(value);
//             }}
//           />
//           <Typography>%</Typography>
//         </Box>
//         <Box
//           sx={{
//             display: "flex",
//             justifyContent: "flex-end",
//             marginTop: "10px",
//           }}
//         >
//           <Button
//             variant="contained"
//             color="primary"
//             sx={{
//               marginTop: "20px",
//             }}
//           >
//             確認
//           </Button>
//         </Box>
//       </CardContent>
//     </Card>
//   );
// };

export default TicketDetail;
