import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect } from "react";

export default function DownloadImageDialog({
  open,
  onClose,
  imageUrl,
  onDownload,
  loading,
  imageType,
}: {
  open: boolean;
  onClose: () => void;
  imageUrl: string;
  onDownload: () => void;
  loading: boolean;
  imageType: string;
}) {

  useEffect(() => {
    const activeElement = document.activeElement as HTMLElement;
    if (activeElement) {
      activeElement.blur();
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>図面ファイルを表示</DialogTitle>
      <DialogContent dividers>
        <div style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%"
        }}
        >
          {loading ? (
            <CircularProgress />
          ) : (
            imageUrl
              ? <img
                src={`data:${imageType};base64,${imageUrl}`}
                alt="図面"
                style={{ maxWidth: "100%", maxHeight: "100%" }}
              />
              : "画像がありません"
          )}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          onClick={onClose}>
          キャンセル
        </Button>
        <Button
          variant="contained"
          onClick={onDownload}
        >
          ダウンロード
        </Button>
      </DialogActions>
    </Dialog>
  );
}
