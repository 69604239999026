import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import React, { useEffect } from "react";

export enum ConfirmDialogType {
  customerAcceptEmployee,
  customerRejectEmployee,
  employeeAcceptCustomer,
  employeeRejectCustomer,
  employeeAcceptSubcontractor,
  employeeRejectSubcontractor,
  subcontractorAcceptEmployee,
  subcontractorRejectEmployee,
  employeeConfirmDelivery,
  subcontractorConfirmDelivery,
}

export default function ConfirmDialog({
  open,
  onClose,
  onConfirm,
  estimationNumber,
  fileName,
  dialogType,
}: {
  open: boolean;
  onClose: () => void;
  onConfirm: () => Promise<void>;
  estimationNumber: string;
  fileName: string;
  dialogType: ConfirmDialogType;
}) {
  const [loading, setLoading] = React.useState(false);
  const [color, setColor] = React.useState("#3E6EB4");
  const [message, setMessage] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [action, setAction] = React.useState("");

  useEffect(() => {
    switch (dialogType) {
      case ConfirmDialogType.customerAcceptEmployee:
        setColor("#3E6EB4");
        setMessage(`「${estimationNumber}」のファイル「${fileName}」の納品物受け入れを完了に変更しますか?`);
        setTitle("受入完了に変更します");
        setAction("受け入れる");
        break;
      case ConfirmDialogType.customerRejectEmployee:
        setColor("#B85450");
        setMessage(`本当に注文「${estimationNumber}」のファイル「${fileName}」のチケットを拒否しますか？`);
        setTitle("チケットを拒否");
        setAction("確認");
        break;
      case ConfirmDialogType.employeeAcceptCustomer:
        setColor("#3E6EB4");
        setMessage(`本当に注文「${estimationNumber}」のファイル「${fileName}」のチケットを承認しますか？`);
        setTitle("受入完了に変更します");
        setAction("受け入れる");
        break;
      case ConfirmDialogType.employeeRejectCustomer:
        setColor("#B85450");
        setMessage(`本当に注文「${estimationNumber}」のファイル「${fileName}」のチケットを拒否しますか？`);
        setTitle("チケットを拒否");
        setAction("確認");
        break;
      case ConfirmDialogType.employeeAcceptSubcontractor:
        setColor("#3E6EB4");
        setMessage(`本当に注文「${estimationNumber}」のファイル「${fileName}」のチケットを承認しますか？`);
        setTitle("受入完了に変更します");
        setAction("受け入れる");
        break;
      case ConfirmDialogType.employeeRejectSubcontractor:
        setColor("#B85450");
        setMessage(`本当に注文「${estimationNumber}」のファイル「${fileName}」のチケットを拒否しますか？`);
        setTitle("チケットを拒否");
        setAction("確認");
        break;
      case ConfirmDialogType.subcontractorAcceptEmployee:
        setColor("#3E6EB4");
        setMessage(`本当に注文「${estimationNumber}」のファイル「${fileName}」のチケットを承認しますか？`);
        setTitle("チケットを承認");
        setAction("受け入れる");
        break;
      case ConfirmDialogType.subcontractorRejectEmployee:
        setColor("#B85450");
        setMessage(`本当に注文「${estimationNumber}」のファイル「${fileName}」のチケットを拒否しますか？`);
        setTitle("チケットを拒否");
        setAction("確認");
        break;
      case ConfirmDialogType.subcontractorConfirmDelivery:
        setColor("#4CAF50");
        setMessage(`本当に注文「${estimationNumber}」のファイル「${fileName}」のチケットを納品しますか？`);
        setTitle("チケットを納品");
        setAction("確認");
        break;
      case ConfirmDialogType.employeeConfirmDelivery:
        setColor("#4CAF50");
        setMessage(`本当に注文「${estimationNumber}」のファイル「${fileName}」のチケットを納品しますか？`);
        setTitle("チケットを納品");
        setAction("確認");
        break;
    }
  }, [dialogType, estimationNumber, fileName]);

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle sx={{
        color: color,
        fontWeight: "bold",
      }}>
        {title}
      </DialogTitle>
      <DialogContent>
        <Typography >
          {message}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          sx={{
            color: "#3C3C43",
            opacity: 0.6,
          }}
          onClick={onClose}>
          キャンセル
        </Button>
        <Button
          variant="text"
          sx={{
            color: color,
          }}
          onClick={async () => {
            setLoading(true);
            await onConfirm();
            setLoading(false);
          }}
        >
          {
            loading ? <CircularProgress size={20} />
              : action
          }
        </Button>
      </DialogActions>
    </Dialog>
  );
}
